export default function TextArea(props) {
  return (
    <>
      <textarea
        className={props.css}
        cols={props.cols}
        rows={props.rows}
        value={props.input}
        disabled={props.disabled}
      />
    </>
  );
}
