import { decode as b64Decode } from "base-64";
import "bootstrap/dist/css/bootstrap.css";
import { useRef, useState } from "react";
import Button from "./components/Button";
import TextArea from "./components/TextArea";
import imageload from "./loading.gif";
import axios from "axios";

{
  /**
   * It usually boils down to these three things:

    #1) Respect the privacy of others.
    #2) Think before you type.
    #3) With great power comes great responsibility.
   */
}
export default function App() {
  const [pd, setPD] = useState(true);
  const [wm, setWM] = useState(true);
  const [pj, setPJ] = useState(true);
  const [tl, setTL] = useState(true);
  const [userIn, setUserInput] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [ci, setCI] = useState(false);

  // const resul = axios({
  //   method: "get",
  //   url: `https://aiwithus.000webhostapp.com/system.php`,
  // }).then((data) => {
  //   setMultiple({ apiKey: data.key });
  //   console.log("Data variable " + data.key);
  // });

  const handleChat = async (e, b) => {
    e.preventDefault();
    setLoading(true);
    const { Configuration, OpenAIApi } = require("openai");
    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAPI_KEY,
    });

    const openai = new OpenAIApi(configuration);
    //async () => {
    const output = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: e.target.value + " " + userIn,
      temperature: 0,
      max_tokens: 900,
    });
    //console.log(output.data.choices[0].text);
    setResponse(
      (response === "" ? userIn : response) +
        "\n\n" +
        output.data.choices[0].text
    );
    setLoading(false);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <h1 className="display-5">AI Pocket Helper</h1>
      </div>
      <img
        src={imageload}
        className={"img-fluid rounded " + (loading ? "" : "d-none")}
        alt="Loading Image"
      />
      <div className={loading ? "d-none " : ""}>
        <div>{userIn}</div>
        <div>
          <TextArea
            css="form-control"
            rows="15"
            cols="80"
            input={response}
            disabled="false"
          />
        </div>
        <br />
        <input
          className="form-control"
          placeholder="Write what you want"
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
        />

        <div className="row mt-3">
          <Button
            action={() => {
              setWM(wm ? false : true);
            }}
            value=""
            css="btn"
            btnName={wm ? "Open Writing Tools" : "Close Writing Tools"}
          />

          <div className={"d-grid col-6 gap-2 " + (wm ? "d-none" : "")}>
            <Button
              css="btn btn-success"
              action={handleChat}
              value="Explain"
              btnName="Explain"
            />
            <Button
              css="btn btn-warning"
              action={handleChat}
              value="Act as a human search engine and tell in details Who is "
              btnName="Who is"
            />

            <Button
              css="btn btn-info"
              action={handleChat}
              value="Create a new article about "
              btnName="News article"
            />
          </div>
          <div className={"d-grid col-6 gap-2 " + (wm ? "d-none" : "")}>
            <Button
              css="btn btn-primary"
              action={handleChat}
              value="act as a university student and write a detailed paper with APA citetion include in-text citetion and references  on "
              btnName="Essay"
            />

            <Button
              css="btn btn-danger"
              action={handleChat}
              value="Generate a detailed List with key points about "
              btnName="Get a list"
            />

            <Button
              css="btn btn-success"
              action={handleChat}
              value="Define what is "
              btnName="Define"
            />
          </div>
        </div>

        <div className="row mt-2">
          {
            //Product based tools
          }
          <Button
            action={(e) => {
              setPD(pd ? false : true);
            }}
            value=""
            css="btn"
            btnName={
              pd ? "Open Product Development" : "Close Product Development"
            }
          />

          <div className={"d-grid col-6 " + (pd ? "d-none" : "")}>
            <Button
              action={handleChat}
              value="Act as a product development specialist and Generate a product description "
              css="btn btn-danger"
              btnName="Product Description"
            />
          </div>

          <div className={" d-grid col-6 " + (pd ? "d-none" : "")}>
            <Button
              action={handleChat}
              value="Act as a branding specialist and Generate catchy Product titles for "
              css="btn btn-warning"
              btnName="Product Title"
            />
          </div>

          {
            //Project Description
          }
          <>
            <Button
              action={() => setPJ(pj ? false : true)}
              value=""
              css="btn"
              btnName={
                pj ? "Open Project Development" : "Close Project Development"
              }
            />

            <div className={" d-grid col-6 mt-2 " + (pj ? "d-none" : "")}>
              <Button
                action={handleChat}
                value="Act as a project development specialist and Generate a project description with mission, vision, value and description "
                css="btn btn-warning"
                btnName="Project Description"
              />
            </div>

            <div className={" d-grid col-6 mt-2 " + (pj ? "d-none" : "")}>
              <Button
                action={handleChat}
                value="Act as a project development specialist and Generate a project Outline with  project title, project objective, project timeline for "
                css="btn btn-info"
                btnName="Project Outline"
              />
            </div>
          </>
          {
            //Language Translation
          }

          <Button
            action={() => setTL(tl ? false : true)}
            value=""
            css="btn "
            btnName={tl ? "Open Translating Tool" : "Close Translating Tool"}
          />

          <div className={" d-grid col-6 mt-2 " + (tl ? "d-none" : "")}>
            <Button
              action={handleChat}
              value="Translate to Persian "
              css="btn btn-primary"
              btnName="To Persian"
            />
          </div>

          <div className={" d-grid col-6 mt-2 " + (tl ? "d-none" : "")}>
            <Button
              action={handleChat}
              value="Translate to Swahili "
              css="btn btn-danger"
              btnName="To swahili"
            />
          </div>

          {
            //Clear
          }

          <div className=" d-grid gap-2 mt-2">
            <Button
              action={handleChat}
              value=""
              css={" btn btn-secondary ms-1 " + (ci ? "" : "d-none")}
              btnName="Custom Input"
            />

            <Button
              action={() => {
                setResponse("");
                setUserInput("");
              }}
              value=""
              css="btn btn-outline-success ms-1 ps-5 pe-5"
              btnName="Clean"
              secAction={() => {
                setCI(ci ? false : true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
