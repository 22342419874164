export default function Button(props) {
  return (
    <button
      className={props.css}
      onClick={props.action}
      value={props.value}
      onDoubleClick={props.secAction}
    >
      {props.btnName}
    </button>
  );
}
